import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadComponent: () => import('./pages/home').then((m) => m.HomePage),
  },
  {
    path: 'punch-edit',
    loadComponent: () => import('./pages/punch-edit').then((m) => m.PunchEditPage),
  },
  {
    path: 'notifications',
    loadComponent: () => import('./pages/notifications').then((m) => m.NotificationsPage),
  },
  {
    path: 'punches',
    loadComponent: () => import('./pages/punches').then((m) => m.PunchesPage),
  },
  {
    path: 'time-sheets',
    loadComponent: () => import('./pages/time-sheets').then((m) => m.TimeSheetsPage),
  },
  {
    path: 'user-config',
    loadComponent: () => import('./pages/user-config').then((m) => m.UserConfigPage),
  },
  {
    path: 'user-info',
    loadComponent: () => import('./pages/user-info').then((m) => m.UserInfoPage),
  },
  {
    path: 'user-registration',
    loadComponent: () => import('./pages/user-registration').then((m) => m.UserRegistrationPage),
  },
  {
    path: 'switch-user',
    loadComponent: () => import('./pages/switch-user').then((m) => m.SwitchUserPage),
  },
  {
    path: 'punch-edit-mbsc',
    loadComponent: () => import('./pages/punch-edit-mbsc').then((m) => m.PunchEditMbscPage),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})], //, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
