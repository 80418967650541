import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { OAuthModuleConfig, OAuthResourceServerErrorHandler, OAuthStorage } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggingService } from './logging.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private readonly authStorage: OAuthStorage,
    private readonly errorHandler: OAuthResourceServerErrorHandler,
    private readonly logger: LoggingService,
    @Optional() private moduleConfig: OAuthModuleConfig,
  ) { }

  private checkUrl(url: string): boolean {
    return (
      this.moduleConfig.resourceServer.allowedUrls.find((u) => url.startsWith(u)) && !url.startsWith('/api/session')
    );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.logger.information(`interceptor called`, {loc: 'interceptor: 22'});
    const url = request.url.toLowerCase();
    console.log('intercepted: ', url);

    if (!this.moduleConfig) {
      return next.handle(request);
    }
    if (!this.moduleConfig.resourceServer) {
      return next.handle(request);
    }
    if (!this.moduleConfig.resourceServer.allowedUrls) {
      return next.handle(request);
    }
    if (!this.checkUrl(url)) {
      console.log('checkUrl is false');
      return next.handle(request);
    }

    const token = this.authStorage.getItem('access_token');
    const headers = token ? request.headers.set('Authorization', `Bearer ${token}`) : request.headers;

    const req = request.clone({ headers });
    return next.handle(req).pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
}
