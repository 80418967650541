import { MbscModule } from '@mobiscroll/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { AppTimerService, AuthStorage, InactivityService } from './services';
import { JwtInterceptor } from './services/jwt.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    MbscModule,
    FormsModule,
    BrowserModule,
    IonicModule.forRoot({
      innerHTMLTemplatesEnabled: true,
    }),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['/api', 'api', 'https://bbe.cam.ngo/api', 'https://localhost:4002', 'https://auth.cam.ngo'],
        sendAccessToken: false,
      },
    }),
    /*
    TODO: This is the start of switching to more of a reactive model instead of imperative
    StoreModule.forRoot({
      app: coreReducer,
    }),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    EffectsModule.forRoot([CoreEffects]),
*/
  ],
  providers: [
    AppTimerService,
    InactivityService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Geolocation,
    {
      provide: OAuthStorage,
      useExisting: AuthStorage,
    },
    AuthStorage,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
