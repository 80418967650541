import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AlertController, LoadingController } from '@ionic/angular';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  pairwise,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { EMPTY, firstValueFrom, forkJoin, from, lastValueFrom, merge, of } from 'rxjs';
import { endOfDay, getYear, setHours, startOfDay } from 'date-fns';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes';
import { ComponentStore } from '@ngrx/component-store';
import { LoggingService } from './logging.service';
import { AuthService } from './auth.service';
import { AuthStorage } from './auth-storage.service';
import { AppTimerService } from './app-timer.service';
import { ConnectionStatus, NetworkService } from './network.service';
import { ApiService } from './api.service';
import {
  LastPunchInterface,
  NoticeInterface,
  Project,
  PunchInterface,
  PunchTypesInterface,
  TimesheetPeriodInterface,
  TimesheetsInterface,
  UbwUserInterface,
  UserPresetsInterface,
} from '../interfaces';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { ToastService } from './toast.service';
import { ValidatePunchTimesInterface } from "./validate-punch-times.interface";

export interface AppStorageState {
  ubwUser: UbwUserInterface | null;
  getLocal: boolean;
  userPresets: UserPresetsInterface | null;
  lastPunch: LastPunchInterface | null;
  userNotices: NoticeInterface[];
}

@Injectable({
  providedIn: 'root',
})
export class AppStorageService extends ComponentStore<AppStorageState> {
  readonly timeZone: string = 'America/New_York';
  appMode: 'kiosk' | 'app';
  isOnline: boolean;
  isModal = false;
  private onUsersUpdated: (users: UbwUserInterface[]) => Promise<void> = () => {
    return Promise.resolve();
  };

  readonly _ubwUser$ = this.select((state) => state.ubwUser);
  readonly ubwUser$ = this.select((state) => ({ ubwUser: state.ubwUser, getLocal: state.getLocal })).pipe(
    filter((user) => !!user.ubwUser),
  );
  readonly setUbwUser = this.updater((state, value: { ubwUser: UbwUserInterface | null; getLocal: boolean }) => {
    // console.trace('setUbwUser');
    return {
      ...state,
      ubwUser: value.ubwUser,
      getLocal: value.getLocal,
    };
  });

  readonly userPresets$ = this.select((state) => state.userPresets).pipe(filter((presets) => !!presets));
  readonly setUserPresets = this.updater((state, userPresets: UserPresetsInterface | null) => ({
    ...state,
    userPresets,
  }));
  readonly lastPunch$ = this.select((state) => state.lastPunch);
  readonly setLastPunch = this.updater((state, lastPunch: LastPunchInterface | null) => ({
    ...state,
    lastPunch,
  }));
  readonly userNotices$ = this.select((state) => state.userNotices);

  readonly setUserNotices = this.updater((state, userNotices: NoticeInterface[] | null) => ({
    ...state,
    userNotices,
  }));
  // private readonly connection: HubConnection;
  private overlays: Promise<any>[] = [];
  private readonly storageKey: string = 'org.christianaidministries.timeclock';

  // noinspection SpellCheckingInspection

  constructor(
    private readonly storage: Storage,
    private readonly logger: LoggingService,
    private readonly appTimer: AppTimerService,
    private readonly loadingController: LoadingController,
    private readonly alertController: AlertController,
    private readonly auth: AuthService,
    private readonly networkService: NetworkService,
    private readonly apiService: ApiService,
    private readonly authStorage: AuthStorage,
    private readonly localStorage: LocalStorageService,
    private readonly toastService: ToastService,
  ) {
    super({
      ubwUser: null,
      getLocal: true,
      userPresets: null,
      lastPunch: {
        punchId: 0,
        action: 'Clock Out',
        status: 'undefined',
        label: 'Clock In/Out',
        bunit: null,
        project: null,
        in: null,
        out: null,
        closed: false,
        approved: false,
      },
      userNotices: null,
    });

    /* next version
    this.connection = new HubConnectionBuilder().withUrl("/api/data").build();
    this.connection.on("notice-posted", (notice) => {
      console.log("HUB - NOTICE: ", notice);
      this.addNotice(notice);
    });
    this.connection.on("lastPunch-updated", (punch: PunchInterface) => {
      console.log("HUB - LAST PUNCH: ", punch);
      this.loadLastPunch([{ punches: [punch], closed: false }]);
    });
    */

    // this.select((s) => s).subscribe((s) => console.log("STORAGE: ", s));

    this.storage.create().then();

    // set ubwUser at startup
    this.setUbwUser(
      from(!!this.auth.subjectId ? this.getUser(this.auth.subjectId) : of(null)).pipe(
        map((user) => ({ ubwUser: user, getLocal: true })),
      ),
    );

    // set appMode at startup, set from setUser function from then on
    this.localStorage.getUsers().then((users) => {
      this.appMode = users && users.length > 1 ? 'kiosk' : 'app';
    });

    // loading app online and/or coming back online after being offline
    this.networkService.onNetworkChange
      .pipe(
        tap((status) => {
          if (status !== undefined) {
            const isOnline = status.status === ConnectionStatus.Online;
            this.isOnline = isOnline;
            this.auth.isOnline = isOnline;
          }
        }),
        pairwise(),
        filter((pairs) => pairs[1].status === ConnectionStatus.Online),
        filter((pairs) => (pairs[0]?.stamp ? pairs[1].stamp - pairs[0].stamp > 10000 : true)),
        tap((pairs) => {
          if (!!this.auth.subjectId && pairs[0].status === ConnectionStatus.Offline) {
            this.overlays.push(this.showOverlay('Checking for un-synced data...'));
          }
        }),
        switchMap(() => this.auth.checkDiscoveryDocument()),
        catchError((err) => {
          this.logger.error('#2.5 catchError', { loc: 'storage: 177', err });
          this.clearOverlay('Failed to sync local data! Please contact support. Error: [' + JSON.stringify(err) + ']');
          // this.presentToast("Local data synced to server failed! Error: " + JSON.stringify(err), 3000, "middle").then();
          return EMPTY;
        }),
        switchMap(() =>
          this.auth.isAuthenticated$.pipe(
            tap((a) => {
              if (!a) {
                this.clearOverlay();
              }
            }),
            filter((a) => a),
            // tap(() => this.logger.information("online, user authenticated", { loc: "storage: 190" })),
            switchMap(() =>
              from(this.getUser(this.auth.subjectId)).pipe(
                switchMap((user) => {
                  if (!!user && this.overlays.length === 0) {
                    this.loadPeriods();
                    if (!user?.timeStamp || Math.abs(this.appTimer.systemTimer.value - user.timeStamp) > 86400000) {
                      return of(this.loadUserData(user.subjectId, user.authResno, user.isOriginal));
                    }
                    return of(this.setUbwUser({ ubwUser: user, getLocal: false })); // set getLocal to false Nov 2021
                  } else {
                    return from(this.loadPeriods()).pipe(
                      first(),
                      switchMap(() =>
                        !!user
                          ? this.loadUserData(user.subjectId, user.authResno, user?.isOriginal ?? true, false)
                          : of(undefined),
                      ),
                    );
                  }
                }),
              ),
            ),
            filter(() => this.appMode === 'kiosk'),
            tap(() => this.auth.signOutOfAuth().subscribe()),
          ),
        ),
      )
      .subscribe();

    // for loading app offline
    this.networkService.onNetworkChange
      .pipe(
        filter((status) => status !== undefined),
        first(),
        filter((status) => status.status === ConnectionStatus.Offline),
        switchMap(() =>
          from(this.getUser(this.auth.subjectId)).pipe(
            filter((user) => !!user),
            first(),
          ),
        ),
      )
      .subscribe((user) => {
        this.auth.subjectId = user.subjectId;
        this.setUbwUser({ ubwUser: user, getLocal: true });
      });

    // need for signing in, to set user in local storage
    this.auth.profile$.pipe(filter((profile) => !!profile)).subscribe((profile) => {
      this.logger.information(`user profile set: ${profile}`, { loc: 'storage: 240', profile });
      this.loadUserData(profile.subjectId, profile.authResno, profile?.isOriginal ?? true, false).then();
    });

    merge(
      this.ubwUser$.pipe(
        // emits when checking for un-synced data
        filter(() => this.overlays.length > 0),
        throttleTime(5000),
        tap(() => console.log('ubwUser emitted, #1: overlay length: ', this.overlays.length)),
      ),
      this.ubwUser$.pipe(
        distinctUntilChanged((a, b) => a.ubwUser?.subjectId === b.ubwUser?.subjectId),
        filter(({ ubwUser }) => !!ubwUser?.subjectId),
        tap((u) => console.log('UbwUser emitted, #2: ', u)),
        // distinctUntilChanged((a, b) => checkObjectEquals(a.ubwUser, b.ubwUser) && a.getLocal === b.getLocal) // added getLocal check Nov 2021
        // kiosk mode, subjectId is empty until token is refreshed or set the second time. See switch-user service
      ),
    ).subscribe({
      next: ({ getLocal }) => {
        this.loadPresets(getLocal).then();
        this.loadLastPunch().then();

        // ensure system time has been set when app loads, then load the current period's punches
        const interval = setInterval(() => {
          if (!!this.appTimer.systemTimer.value) {
            clearInterval(interval);
            this.getPeriods(this.appTimer.systemTimer.value)
              .then((periods) => {
                this.getPunches(periods.map((p) => p.id))
                  .then(() => {
                    // this.logger.information("AppStorage getPunches finished", { loc: "storage: 271", o: this.overlays.length });
                    if (this.overlays.length > 0) {
                      this.clearOverlay('Local data synced to server successfully!');
                      // this.presentToast("Local data synced to server successfully!", 3000, "middle").then();
                    }
                  })
                  .catch(() =>
                    this.clearOverlay('Failed to sync local data! getPunches failed. Please contact support.'),
                  );
              })
              .catch(() => this.clearOverlay('Failed to sync local data! getPeriods failed. Please contact support.'));
          }
        }, 100);
      },
      error: () => this.clearOverlay('Failed to sync local data! ubwUser subscribe failed. Please contact support.'),
      complete: () => console.log('ubwUser subscribe completed.'),
    });

    // handles offline, kiosk mode, setting user's subjectId so presets and lastPunch get populated from above observable
    this.ubwUser$
      .pipe(
        throttleTime(5000),
        filter((user) => !user.ubwUser.subjectId && !this.isOnline),
        map((user) => ({
          ubwUser: { ...user.ubwUser, subjectId: this.auth.subjectId },
          getLocal: user.getLocal,
          updateSource: null,
        })),
      )
      .subscribe((user) => this.setUbwUser(user));

    /* next version
    // fire up websocket
    zip(this.ubwUser$, merge(of(this.networkService.getCurrentNetworkStatus()), this.networkService.onNetworkChange))
      .pipe(
        filter(([{ ubwUser }, networkStatus]) => ubwUser.subjectId && networkStatus === ConnectionStatus.Online),
        first(),
        tap(() => console.log("appMode: ", this.appMode)),
        filter(() => this.appMode === "app")
      )
      .subscribe(([{ ubwUser }]) => {
        this.initHub(ubwUser.authResno);
      });
    */
  }

  /* next version
  private initHub(authResno: string): void {
    console.log("HUB - INITIALIZING for: ", authResno);
    // const authResno = await this.authResno;
    this.connection.start().then(() => {
      console.log("HUB - STARTED for: ", authResno);
      this.connection.send("sub", `resno/${authResno}`).then(() => {
        // TODO:
      });
    });
  }
  */

  async setUsers(users: UbwUserInterface[], updateUser: boolean = true): Promise<void> {
    await this.localStorage.setUsers(users);
    if (users.length > 1) {
      this.appMode = 'kiosk';
      if (updateUser) {
        await this.onUsersUpdated(users);
      }
    } else {
      this.appMode = 'app';
    }
  }

  setOnUsersUpdated(handler: (users: UbwUserInterface[]) => Promise<void>) {
    this.onUsersUpdated = handler;
  }

  get authResno(): Promise<string> {
    return firstValueFrom(this.ubwUser$.pipe(map((user) => user.ubwUser?.authResno)));
  }

  get punchTypes(): PunchTypesInterface[] {
    return [
      { value: 'punch', name: 'Work', disabled: false },
      { value: 'vacation', name: 'Vacation', disabled: false },
      { value: 'sick', name: 'Sick', disabled: false },
    ];
  }

  getPunchTypeName(val: string): string {
    if (val === 'holiday') {
      return 'Holiday';
    }
    const punchTypes = this.punchTypes;
    if (punchTypes) {
      for (const option of punchTypes) {
        if (val === option.value) {
          return option.name;
        }
      }
    }
    return null;
  }

  async loadPeriods(): Promise<void> {
    try {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth();
      const years = [year];
      if (month < 3) years.unshift(year - 1);
      if (month > 8) years.push(year + 1);
      for (const year of years) {
        const lastModified = await this.localStorage.getLastModified(`periods:${year}`);
        this.logger.information('loadPeriods lastModified', { loc: 'storage: 383', year, lastModified });
        const resp = (await lastValueFrom(
          this.apiService
            .getData<TimesheetPeriodInterface[]>(`periods?periodType=TS&year=${year}`, {
              includeLastModified: true,
              ifModifiedSince: lastModified,
            })
            .pipe(
              catchError((err: HttpErrorResponse) => {
                if (err.status === 304) {
                  return of(null);
                }
                throw err;
              }),
            ),
        )) as { data: TimesheetPeriodInterface[]; lastModified: string };
        if (resp) {
          await this.localStorage.setPeriods(year, resp.data);
          await this.localStorage.setLastModified(`periods:${year}`, resp.lastModified);
        }
      }
      // cleanup; remove previous year
      await this.storage.remove(`${this.storageKey}/periods:${years[0] - 1}`);
      // cleanup legacy periods
      await this.storage.remove(`${this.storageKey}/periods`);
      // Calling this here for now
      await this.loadNotices();
    } catch (e) {
      this.logger.error('loadPeriods catch error', { loc: 'storage: 411', error: e });
      await this.showAlert(
        'Periods',
        `Oops, there has been a problem loading timesheet periods. Please contact support. Error: [${JSON.stringify(
          e,
        )}]`,
      );
    }
  }

  async getPeriods(
    now: string | number | Date,
    range: { start: number; end: number } = { start: 0, end: 0 },
  ): Promise<{ id: number; start: string; end: string; closed: boolean; current: boolean }[] | undefined> {
    const currentTime = utcToZonedTime(now ?? new Date(), this.timeZone);
    const timezoneNow = format(currentTime, 'yyyy-MM-dd', { timeZone: this.timeZone });
    const periods = await this.localStorage.getPeriods(getYear(currentTime));
    const index = periods
      ? periods.findIndex((p) => timezoneNow >= p.dateFrom.substring(0, 10) && timezoneNow <= p.dateTo.substring(0, 10))
      : undefined;

    const data: { id: number; start: string; end: string; closed: boolean; current: boolean }[] = [];
    if (index !== undefined && index >= 0) {
      const endIndex = index + 1 + range.end;
      for (let i = index - range.start; i < endIndex; i++) {
        data.push({
          id: periods[i].periodId,
          start: zonedTimeToUtc(startOfDay(new Date(periods[i].dateFrom)), this.timeZone).toISOString(),
          end: zonedTimeToUtc(endOfDay(new Date(periods[i].dateTo)), this.timeZone).toISOString(),
          closed: periods[i].status === 'closed',
          current: i === index,
        });
      }
    } else {
      this.logger.error('AppStorage getPunches - missing period', {
        loc: 'storage: 446',
        data: { now, range, timezoneNow, periods, index, data },
      });
    }
    return data;
  }

  async loadUserData(
    subjectId: string,
    authResno: string,
    isOriginal: boolean,
    getLocal: boolean = true,
  ): Promise<void> {
    this.logger.information('calling employees endpoint', { loc: 'storage: 459', authResno });
    await lastValueFrom(
      this.apiService.getData<UbwUserInterface>(`employees/${authResno}`).pipe(
        map((res) => res as UbwUserInterface),
        catchError(() => of(null)),
        first(),
        switchMap(async (user) => {
          if (!user) {
            const localUser = await this.getUser(subjectId);
            return { user, pass: !!localUser && localUser.subjectId !== subjectId };
          }
          return { user, pass: true };
        }),
        tap(({ user, pass }) => console.log('loadUserData values: ', user, pass)),
        map(({ user, pass }) =>
          user
            ? {
              user: {
                ...user,
                subjectId,
                authResno,
                isOriginal,
                timeStamp: this.appTimer.systemTimer.value,
              },
              pass,
            }
            : { user: null, pass },
        ),
        tap(({ user, pass }) => {
          if (pass) {
            this.setUbwUser({ ubwUser: user, getLocal });
          }
        }),
        switchMap(({ user, pass }) => (pass ? from(this.setUser(user)) : of(null))),
      ),
    );
  }

  private async setUser(user: UbwUserInterface): Promise<void> {
    // check and add user to users array
    const users = (await this.localStorage.getUsers()) ?? [];
    // look for user in existing data
    const userIndex = users.findIndex((u) => u.subjectId === user.subjectId);
    if (userIndex < 0 && users.length === 1) {
      const alert = await this.alertController.create({
        header: 'Add or Replace User?',
        message: `Another user is registered on this device.
          Do you want to <u>add</u> or <u>replace</u> this user on this device?</br>
          <b>NOTE: Adding this user will put this device into Kiosk mode.</b>`,
        buttons: [
          {
            text: 'Add',
            handler: () => {
              users.push(user);
            },
          },
          {
            text: 'Replace',
            handler: async () => {
              const oldSubjectId = users[0].subjectId;
              users[0] = user;
              await this.localStorage.removeUser(oldSubjectId);
            },
          },
        ],
      });
      alert.present().then();
      await alert.onDidDismiss();
    } else {
      if (userIndex < 0) {
        users.push(user);
      } else {
        users[userIndex] = user;
      }
    }

    await this.setUsers(users);
  }

  async getUser(subjectId: string): Promise<UbwUserInterface | undefined> {
    this.logger.information('getUser called', { loc: 'storage: 539', subjectId });
    const users = await this.localStorage.getUsers();
    return users.find((u) => u.subjectId === subjectId);
  }

  async removeUser(subjectId: string): Promise<void> {
    this.authStorage.removeUser(subjectId);
    const users = await this.localStorage.getUsers();
    // look for user in existing data
    const u = users.findIndex((user) => user.subjectId === subjectId);
    if (u >= 0) {
      const subjectId = users[u].subjectId;
      await this.localStorage.removeUser(subjectId);
      users.splice(u, 1);
      await this.setUsers(users);
    } else {
      await this.showAlert('Remove User', 'No user found to remove.');
    }
  }

  // add notice one-off, from device or websocket
  async addNotice(notice: NoticeInterface): Promise<void> {
    const subjectId = this.auth.subjectId;
    const notices = await this.localStorage.getNotices(subjectId);
    // find and either update or add this notice
    const index = notices.findIndex((n) => notice.id !== 0 && n.id === notice.id);
    if (index >= 0) {
      notices[index] = notice;
    } else {
      notices.push(notice);
    }
    // coming from the device, id is 0 and needs to be sent to server
    // otherwise it's coming from server through websocket, id is set
    if (notice.id === 0) {
      await this.loadNotices(notices);
    } else {
      await this.localStorage.setNotices(subjectId, notices);
      this.setUserNotices(notices);
    }
  }

  async dismissNotice(id: number): Promise<void> {
    const subjectId = this.auth.subjectId;
    const notices = await this.localStorage.getNotices(subjectId);
    const index = notices.findIndex((n: NoticeInterface) => n.id === id);
    if (index >= 0) {
      notices[index].dismissed = true;
      notices[index].update = true;
      await this.loadNotices(notices);
    } else {
      await this.showAlert('Dismiss Notification', 'No notice found to dismiss. Please contact support.');
    }
  }

  async loadNotices(notices?: NoticeInterface[]): Promise<void> {
    try {
      const subjectId = this.auth.subjectId;
      if (!notices) {
        notices = await this.localStorage.getNotices(subjectId);
      }
      if (this.isOnline) {
        const authResno = await this.authResno;
        const noticesToDismiss = notices.filter((n: NoticeInterface) => n.id !== 0 && n.update);
        if (noticesToDismiss.length > 0) {
          await lastValueFrom(
            forkJoin(
              noticesToDismiss.map((notice) =>
                this.apiService.postData<NoticeInterface, void>(`notices/${notice.id}`, {
                  ...notice,
                }),
              ),
            ),
          );
        }
        const noticesToPost = notices.filter((n: NoticeInterface) => n.id === 0);
        if (noticesToPost.length > 0) {
          await lastValueFrom(
            forkJoin(
              noticesToPost.map((notice) =>
                this.apiService.postData<NoticeInterface, void>(`notices/post/${authResno}`, {
                  ...notice,
                }),
              ),
            ),
          );
        }
        notices = (await firstValueFrom(
          this.apiService.getData<NoticeInterface[]>(`notices/${authResno}`),
        )) as NoticeInterface[];
      }
      await this.localStorage.setNotices(subjectId, notices);
      this.setUserNotices(notices);
    } catch (e) {
      this.logger.error('loadNotices error: ', { loc: 'storage: 632', error: e });
      await this.showAlert(
        'Notifications',
        `Oops, something went wrong getting your notifications. Please contacts support. Error: ${JSON.stringify(e)}`,
      );
    }
  }

  async getProjects(getLocal: boolean = false): Promise<Project[]> {
    try {
      let projects: Project[];
      const isAllocated = await firstValueFrom(this.ubwUser$.pipe(map((user) => user.ubwUser.isAllocated)));
      if (isAllocated) {
        projects = [
          {
            id: 'Allocated',
            name: 'Special Allocated Project',
            businessUnit: 'CAM/TGS',
            costCenter: '',
          },
        ];
      } else {
        projects = await this.localStorage.getProjects();
        if ((!projects || !getLocal) && this.isOnline) {
          projects = (await lastValueFrom(
            this.apiService.getData<Project[]>('projects'),
          )) as Project[];
          await this.localStorage.setProjects(projects);
        }

        if (projects) {
          projects.sort((a, b) =>
            (a.businessUnit + ': ' + a.id)
              .toLocaleLowerCase()
              .localeCompare((b.businessUnit + ': ' + b.id).toLocaleLowerCase()),
          );
        }
      }
      return projects;
    } catch (e) {
      this.logger.error('getProjects error: ', { loc: 'storage: 672', error: e });
      return null;
    }
  }

  async loadPresets(getLocal: boolean = false, projects?: Project[]): Promise<void> {
    this.logger.information('loadPresets called ', { loc: 'storage: 678', getLocal, projects });
    const subjectId = this.auth.subjectId;
    let presets = await this.localStorage.getPresets(subjectId);
    if ((!presets || !getLocal) && this.isOnline) {
      try {
        const serverPresets = (await lastValueFrom(
          this.apiService.getData<UserPresetsInterface>(`user-configuration/`),
        )) as UserPresetsInterface;
        // remove subjectId if present, Feb 2024
        if (serverPresets && serverPresets['subjectId']) {
          this.logger.information('removing subjectId from server presets ', { loc: 'storage: 688', subjectId: serverPresets['subjectId'] });
          delete serverPresets['subjectId'];
        }
        if (presets && serverPresets && presets.timeStamp > serverPresets.timeStamp) {
          this.savePresets({ ...presets, userPin: presets?.userPin ?? serverPresets?.userPin }).then();
        } else {
          presets = { ...serverPresets, userPin: serverPresets?.userPin ?? presets?.userPin };
          await this.localStorage.setPresets(subjectId, presets);
        }
      } catch (e) {
        this.logger.error('loadPresets error: ', { loc: 'storage: 694', error: e });
        if (e.status !== 404) {
          await this.showAlert(
            'Configuration',
            'Oops, something went wrong getting your configuration. Please contacts support. Error: ' +
            JSON.stringify(e),
          );
        }
      }
    }

    if (presets) {
      if (!projects) {
        projects = await this.getProjects(getLocal);
      }
      presets.data = presets.data.filter(({ project }) => projects.some((p) => p.id === project));
      // correct intermittent problem of wrong businessUnit and/or costCenter
      presets.data.forEach((preset) => {
        const { businessUnit, costCenter } = projects.filter((p) => p.id === preset.project)[0];
        preset.businessUnit = businessUnit;
        preset.costCenter = costCenter;
      });
      if (presets.data.filter((preset) => preset.value === presets.value).length < 0) {
        presets.value = null;
      }
    }
    if (!presets || presets.data.length === 0) {
      // moved alert to home page, so it shows every time user comes back to home page
      // await this.showAlert('Configuration', 'It appears your configuration is incomplete. You will need to configure your projects before you can punch time.');
      presets = { value: null, data: [], timeStamp: null };
    }
    this.setUserPresets(presets);
  }

  async savePresets(presets: UserPresetsInterface): Promise<void> {

    try {
      if (this.isOnline) {
        await lastValueFrom(
          this.apiService.postData<UserPresetsInterface, void>(`user-configuration/`, {
            ...presets,
          }),
        );
      }
      this.setUserPresets(presets);
      await this.localStorage.setPresets(this.auth.subjectId, presets);
    } catch (e) {
      await this.showAlert(
        'Configuration',
        `Oops, something went wrong saving your configuration. Please contact support. Error: ${JSON.stringify(e)}`,
      );
    }
  }

  validatePunchTimes(
    punch: ValidatePunchTimesInterface,
    punches: PunchInterface[],
    displayMsg: boolean = true,
  ): boolean {
    const punchTypes = punch.punchType !== 'punch' ? ['vacation', 'holiday', 'sick'] : ['punch'];
    const sysTime = roundToNearestMinutes(new Date(punch.systemTime));
    const a = {
      start: roundToNearestMinutes(new Date(punch.inDateTime)),
      end: punch.outDateTime === null ? sysTime : roundToNearestMinutes(new Date(punch.outDateTime)),
      open: punch.outDateTime === null,
    };

    for (var i = 0; i < punches.length; i++) {
      if (
        punchTypes.indexOf(punches[i].punchType) >= 0 &&
        !punches[i].deleted &&
        (punch.id > 0 ? punch.id !== punches[i].id : punch.punchId !== punches[i].punchId)
      ) {
        const b = {
          start: roundToNearestMinutes(new Date(punches[i].inDateTime)),
          end: punches[i].outDateTime === null ? sysTime : roundToNearestMinutes(new Date(punches[i].outDateTime)),
          open: punches[i].outDateTime === null,
        };
        if ((a.start < b.end && b.start < a.end) || (a.open && b.open)) {
          break;
        }
      }
    }
    if (i < punches.length) {
      // validation failed
      this.logger.warning('punch validation failed', { loc: 'storage: 779', a: punch, b: punches[i] });
      if (displayMsg) {
        const ptn = this.getPunchTypeName(punches[i].punchType);
        const atn = this.getPunchTypeName(punch.punchType);
        const header = punch.punchType !== 'punch' ? `Record ${atn} Time` : 'Save Punch';
        this.showAlert(
          header,
          `Oops, you have ${(ptn !== atn ? 'a ' : 'another ') + ptn}
          punch that conflicts with this ${ptn !== atn ? atn + ' punch' : ' one'}.
          Please check your entries and try again.`,
        ).then();
      }
      return false;
    } else {
      // validation passed
      return true;
    }
  }

  async validatePTOPunch(data: {
    ptoDate: Date;
    ptoHours: string;
    ptoDays: number;
    punchData: {
      ptoType: string;
      id: number;
      punchId: number;
    };
    week: {
      periodId: number;
      start: string;
      end: string;
    };
  }): Promise<void | {
    punches: PunchInterface[];
    id: number;
    sdt: string;
    edt: string;
    businessUnit: string;
    costCenter: string;
    project: string;
    ptoDays: { in: string; out: string }[];
  }> {
    const anotherTypeName = this.getPunchTypeName(data.punchData.ptoType);
    const ptoDays: { in: string; out: string }[] = [];

    if (
      isNaN(data.ptoDate.getTime()) ||
      data.ptoDate.toISOString() < data.week.start ||
      data.ptoDate.toISOString() > data.week.end
    ) {
      await this.showAlert(`Record ${anotherTypeName} Day`, `Invalid date. Please select the ${anotherTypeName} Date.`);
      return;
    }

    if (!data.ptoHours) {
      await this.showAlert(
        `Record ${anotherTypeName} Day`,
        `Please select the duration for this ${anotherTypeName} day.`,
      );
      return;
    }

    const ptoHours = data.ptoHours === 'range' ? ['8', '16'] : data.ptoHours.split('-');
    const inDateTime = zonedTimeToUtc(setHours(data.ptoDate, +ptoHours[0]), this.timeZone).toISOString();
    const outDateTime = zonedTimeToUtc(setHours(data.ptoDate, +ptoHours[1]), this.timeZone).toISOString();

    const timeSheet = (await this.getPunches([data.week.periodId], false))[0];
    // check for other conflicting punches
    const punch = {
      id: data.punchData.id,
      punchId: data.punchData.punchId,
      punchType: data.punchData.ptoType,
      inDateTime,
      outDateTime: outDateTime,
      systemTime: null,
    };

    let isValid = this.validatePunchTimes(punch, timeSheet.punches);
    let totalHours = +ptoHours[1] - +ptoHours[0];
    for (let i = 2; isValid && i <= data.ptoDays; i++) {
      totalHours += +ptoHours[1] - +ptoHours[0];
      data.ptoDate.setDate(data.ptoDate.getDate() + 1);
      const inDT = zonedTimeToUtc(setHours(data.ptoDate, +ptoHours[0]), this.timeZone).toISOString();
      const outDT = zonedTimeToUtc(setHours(data.ptoDate, +ptoHours[1]), this.timeZone).toISOString();
      isValid = this.validatePunchTimes(
        {
          id: data.punchData.id,
          punchId: data.punchData.punchId,
          punchType: data.punchData.ptoType,
          inDateTime: inDT,
          outDateTime: outDT,
          systemTime: null,
        },
        timeSheet.punches,
      );
      if (isValid) {
        ptoDays.push({ in: inDT, out: outDT });
      } else {
        break;
      }
    }

    if (isValid) {
      const user = await firstValueFrom(
        this.ubwUser$.pipe(
          map((u) => u.ubwUser),
          map((u) => ({
            ...u,
            businessUnit: u.benefitsBusinessUnit,
            costCenter: u.benefitsCostCenter,
            project: u.benefitsProject,
          })),
        ),
      );

      if (
        !(await this.isEmployeeEligibleForPto(
          data,
          timeSheet,
          inDateTime,
          outDateTime,
          anotherTypeName,
          totalHours))
      ) {
        return;
      }

      return {
        punches: timeSheet.punches,
        id: new Date(inDateTime).getTime(),
        sdt: inDateTime,
        edt: outDateTime,
        businessUnit: user.businessUnit,
        costCenter: user.costCenter,
        project: user.project,
        ptoDays,
      };
    }
  }

  private async isEmployeeEligibleForPto(
    data: {
      ptoDate: Date;
      ptoHours: string;
      ptoDays: number;
      punchData: {
        ptoType: string;
        id: number;
        punchId: number;
      };
      week: {
        periodId: number;
        start: string;
        end: string;
      };
    },
    timeSheet: TimeSheet,
    inDateTime: string,
    outDateTime: string,
    anotherTypeName: string,
    totalHours: number,
  ): Promise<boolean> {
    const employee = await firstValueFrom(
      this.ubwUser$.pipe(
        map((u) => u.ubwUser),
        map((u) => ({
          ...u,
          businessUnit: u.benefitsBusinessUnit,
          costCenter: u.benefitsCostCenter,
          project: u.benefitsProject,
        })),
      ),
    );

    if (
      (data.punchData.ptoType === 'vacation' && inDateTime < new Date(employee.vacationEligibility).toISOString()) ||
      (data.punchData.ptoType === 'sick' && inDateTime < new Date(employee.sickTimeEligibility).toISOString())
    ) {
      await this.showAlert(
        `Record ${anotherTypeName} Day`,
        `Oops, it appears you are not eligible for ${anotherTypeName} time yet.`,
      );
      return false;
    } else {
      // check for unprocessed PTO punches
      let ptoHours = 0;
      for (let i = 0; i < timeSheet.punches.length; i++) {
        if (
          (data.punchData.id && data.punchData.punchId
            ? data.punchData.id > 0
              ? data.punchData.id !== timeSheet.punches[i].id
              : data.punchData.punchId !== timeSheet.punches[i].punchId
            : true) &&
          timeSheet.punches[i].punchType === data.punchData.ptoType &&
          (timeSheet.punches[i].id === 0 || timeSheet.punches[i].update) &&
          !timeSheet.punches[i].deleted &&
          !timeSheet.closed
        ) {
          ptoHours +=
            new Date(timeSheet.punches[i].outDateTime).getHours() -
            new Date(timeSheet.punches[i].inDateTime).getHours();
        }
      }
      if (
        (data.punchData.ptoType === 'vacation' && employee.vacationBalance - ptoHours < totalHours) ||
        (data.punchData.ptoType === 'sick' && employee.sickTimeBalance - ptoHours < totalHours)
      ) {
        await this.showAlert(
          `Record ${anotherTypeName} Day`,
          `Oops, it appears you do not have enough ${anotherTypeName} time to record this paid time off.</br>NOTE: You have ${data.punchData.ptoType === 'vacation' ? employee.vacationBalance : employee.sickTimeBalance
          } hours of ${anotherTypeName} time available and are trying to enter ${totalHours} hours.`,
        );
        return false;
      }
    }

    return true;
  }

  async getPunches(
    periodIds?: number[],
    setLocal: boolean = true,
  ): Promise<{ periodId: number; closed: boolean; punches: PunchInterface[] }[]> {
    const subjectId = this.auth.subjectId;
    let pdata = await this.localStorage.getPunches(subjectId);
    if (this.isOnline && periodIds !== undefined) {
      const { authResno, camResno, tgsResno, benefitsResno, location, isOriginal } = await firstValueFrom(
        this.ubwUser$.pipe(map((user) => user.ubwUser)),
      );
      if (pdata) {
        await this.setServerPunchesIfNeeded(
          this.flattenPunches(pdata),
          location,
          authResno,
          camResno,
          tgsResno,
          benefitsResno,
          subjectId,
          isOriginal,
        );
      }
      pdata = await this.getServerPunches(authResno, periodIds);
    }

    if (this.isOnline && setLocal) {
      await this.localStorage.setPunches(subjectId, pdata ?? null);
    }
    return pdata;
  }

  async savePunches(
    punches: PunchInterface[],
    period: { id: number; closed: boolean },
    sendToServer: boolean = true,
  ): Promise<void> {
    // this.logger.information(`savePunches called`, { loc: 'storage: 1053', punches, period, sendToServer });
    // let pdata = [];
    try {
      const subjectId = this.auth.subjectId;
      const pdata = await this.localStorage.getPunches(subjectId);
      // this.logger.information(`savePunches, ready to run forEach`, { loc: "storage: 1040", pdata });
      punches.forEach((punch) => {
        const idx = pdata.findIndex(
          (p: { closed: boolean; punches: PunchInterface[]; periodId: number }) => p.periodId === punch.periodId,
        );
        if (idx < 0) {
          pdata.push({ closed: period.closed, periodId: punch.periodId, punches: [punch] });
        } else {
          const i = pdata[idx].punches.findIndex((p: PunchInterface) =>
            punch.id > 0 ? punch.id === p.id : punch.punchId === p.punchId,
          );
          if (i < 0) {
            pdata[idx].punches.push(punch);
          } else {
            pdata[idx].punches[i] = punch;
          }
        }
      });

      pdata.sort((a, b) => a.periodId - b.periodId);
      this.loadLastPunch(pdata).then();
      await this.localStorage.setPunches(subjectId, pdata);

      // only save if online and periodId is supplied
      if (this.isOnline && sendToServer) {
        // online so save to server and then update data to local storage
        this.ubwUser$
          .pipe(
            first(),
            map((user) => user.ubwUser),
            tap(({ authResno, camResno, tgsResno, benefitsResno, location, isOriginal }) => {
              this.setServerPunchesIfNeeded(
                this.flattenPunches(pdata),
                location,
                authResno,
                camResno,
                tgsResno,
                benefitsResno,
                subjectId,
                isOriginal,
                [period.id],
              ).then();
            }),
          )
          .subscribe();
      }
    } catch (e) {
      this.logger.error(`Error saving punches`, { loc: 'storage: 1087', error: e, punches });
      await this.showAlert(
        'Save Punches',
        'Oops, something went wrong saving your punches! Please contact support. Error: ' + JSON.stringify(e),
      );
    }
  }

  async getLastPunch(): Promise<{ punch: PunchInterface; closed: boolean }> {
    let pdata: { punch: PunchInterface; closed: boolean };
    if (this.isOnline) {
      const authResno = await this.authResno;
      if (!!authResno) {
        try {
          pdata = (await lastValueFrom(
            this.apiService.getData<{ punch: PunchInterface; closed: boolean }>(`punches/${authResno}/last`),
          )) as { punch: PunchInterface; closed: boolean };
          if (!pdata) {
            pdata = { punch: null, closed: false };
          }
        } catch (e) {
          this.showAlert(
            'Last Punch',
            'Oops, something went wrong getting your last punch! Please contact support. Error: ' + JSON.stringify(e),
          ).then();
        }
      } else {
        this.showAlert('Last Punch', 'Oops, missing Resno for getting your last punch! Please contact support.').then();
      }
    } else {
      const subjectId = this.auth.subjectId;
      const timeSheets = await this.localStorage.getPunches(subjectId);
      const punches = this.flattenPunches(timeSheets).filter((p) => p.punchType === 'punch' && !p.deleted);
      const i = punches.length - 1;
      if (i >= 0) {
        const { closed, ...punch } = punches[i];
        pdata = { punch, closed };
      } else {
        pdata = { punch: null, closed: false };
      }
    }
    return pdata;
  }

  async loadLastPunch(timeSheets?: TimeSheet[]): Promise<void> {
    let lastPunch: { punch: PunchInterface; closed: boolean };
    if (!!timeSheets) {
      const punches = this.flattenPunches(timeSheets)
        .filter((p) => p.punchType === 'punch' && !p.deleted)
        .sort((a, b) => a.punchId - b.punchId);
      const i = punches.length - 1;
      if (i >= 0) {
        const { closed, ...punch } = punches[i];
        lastPunch = { punch, closed };
      } else {
        lastPunch = { punch: null, closed: false };
      }
    } else {
      lastPunch = await this.getLastPunch();
      if (lastPunch === undefined) return;
    }
    // this.logger.information("loadLastPunch", { loc: "storage: 1148", lastPunch });

    if (!!lastPunch?.punch) {
      const isClockedIn = lastPunch.punch.inDateTime !== null && lastPunch.punch.outDateTime === null;
      this.setLastPunch({
        punchId: lastPunch.punch.punchId,
        action: isClockedIn ? 'Clock In' : 'Clock Out',
        status: isClockedIn ? 'Clocked In' : 'Clocked Out',
        label: isClockedIn ? 'Clock Out' : 'Clock In',
        bunit: lastPunch.punch.businessUnit,
        project: lastPunch.punch.project,
        in:
          lastPunch.punch.inDateTime !== null
            ? roundToNearestMinutes(new Date(lastPunch.punch.inDateTime)).toISOString()
            : null,
        out:
          lastPunch.punch.outDateTime !== null
            ? roundToNearestMinutes(new Date(lastPunch.punch.outDateTime)).toISOString()
            : null,
        closed: lastPunch.closed,
        approved: lastPunch.punch.userApproved,
      });
    } else {
      // brand-new user, no previous punches
      // noinspection SpellCheckingInspection
      this.setLastPunch({
        punchId: 0,
        action: 'Clock Out',
        status: 'undefined',
        label: 'Clock In',
        bunit: null,
        project: null,
        in: null,
        out: null,
        closed: false,
        approved: false,
      });
    }
  }

  private flattenPunches(timeSheets: TimeSheet[]): (PunchInterface & { closed: boolean })[] {
    const punches: (PunchInterface & { closed: boolean })[] = [];
    timeSheets.forEach((period) => {
      if (period?.punches) punches.push(...period.punches.map((p) => ({ ...p, closed: period.closed })));
    });
    return punches.sort((a, b) => a.punchId - b.punchId);
  }

  async getTimesheets(periodId: number): Promise<TimesheetsInterface | undefined> {
    try {
      const authResno = await this.authResno;
      if (!!authResno) {
        return (await lastValueFrom(
          this.apiService.getData<TimesheetsInterface>(`timesheets/${authResno}/${periodId}`),
        )) as TimesheetsInterface;
      } else {
        this.showAlert(
          'Get Timesheet',
          'Oops, missing Resno for getting your timesheet! Please contact support.',
        ).then();
        return undefined;
      }
    } catch (e) {
      if (e.status === 404) {
        await this.toastService.presentToast('Timesheet not found for this period. PeriodId: ' + periodId);
      } else {
        await this.showAlert(
          'Get Timesheet',
          `Oops, something went wrong getting timesheet data. Error: [${JSON.stringify(e)}]`,
        );
      }
      return undefined;
    }
  }

  async saveTimesheets(periodId: number): Promise<TimesheetsInterface | undefined> {
    const authResno = await this.authResno;
    if (!!authResno) {
      return await lastValueFrom(this.apiService.postData<any, undefined>(`timesheets/${authResno}/${periodId}`, {}));
    } else {
      this.showAlert('Save Timesheet', 'Oops, missing Resno for saving your timesheet! Please contact support.').then();
      return undefined;
    }
  }

  private async setServerPunchesIfNeeded(
    punches: PunchInterface[],
    location: string,
    authResno: string,
    camResno: string,
    tgsResno: string,
    benefitsResno: string,
    subjectId: string,
    isOriginal: boolean,
    periodIds?: number[],
  ): Promise<void> {
    const punchesToSet = punches.filter((punch) => punch.id === 0 || punch.update);
    if (punchesToSet.length > 0) {
      await this.setServerPunches(
        punchesToSet,
        location,
        authResno,
        camResno,
        tgsResno,
        benefitsResno,
        subjectId,
        isOriginal,
        periodIds,
      );
    }
  }

  private async setServerPunches(
    punches: PunchInterface[],
    location: string,
    authResno: string,
    camResno: string,
    tgsResno: string,
    benefitsResno: string,
    subjectId: string,
    isOriginal: boolean,
    periodIds?: number[],
  ): Promise<void> {
    try {
      await lastValueFrom(
        this.apiService.postData<PunchInterface[], void>(
          `punches/`,
          punches.map((punch: PunchInterface) => ({
            ...punch,
            location,
            resno:
              punch.punchType !== 'punch'
                ? benefitsResno
                : punch.businessUnit === 'CAM' || punch.project === 'Allocated'
                  ? camResno
                  : tgsResno,
          })),
        ),
      );

      // update user data for sick and vacation punches
      if (punches.filter((sp) => sp.punchType !== 'punch').length > 0) {
        this.loadUserData(subjectId, authResno, isOriginal ?? true).then();
      }

      // get punches and update local storage on condition
      if (!!authResno && !!periodIds) {
        const serverPunches = await this.getServerPunches(authResno, periodIds);

        if (!!serverPunches) {
          this.loadLastPunch(serverPunches).then();
          this.localStorage.setPunches(subjectId, serverPunches).then();
        }
      }
    } catch (e) {
      this.logger.error(`setServerPunches Error`, { loc: 'storage: 1303', error: e.error });
      this.showAlert(
        'Save Punches Error',
        'Error from savePunches: ' + (typeof e.error.message === 'string' ? e.error.message : JSON.stringify(e.error)),
      ).then();
    }
  }

  async getServerPunches(
    authResno: string,
    periodIds: number[],
  ): Promise<{ punches: PunchInterface[]; closed: boolean; periodId: number }[] | undefined> {
    if (!!authResno) {
      return await lastValueFrom(
        forkJoin(
          periodIds.map((periodId) =>
            this.apiService
              .getData<{ punches: PunchInterface[]; closed: boolean }>(`punches/${authResno}/${periodId}`)
              // .map(res => res as PunchInterface[]),
              .pipe(
                map((res) => res as { punches: PunchInterface[]; closed: boolean }),
                map((punchObj) => ({
                  closed: punchObj.closed,
                  punches: punchObj.punches.sort((a, b) => a.punchId - b.punchId),
                  periodId,
                })),
              ),
          ),
        ),
      );
    } else {
      this.showAlert('Get Punches', 'Oops, missing Resno for getting your punches! Please contact support.').then();
      return undefined;
    }
  }

  showOverlay(msg: string): Promise<any> {
    return this.loadingController
      .create({
        message: msg,
      })
      .then(async (o) => {
        await o.present();
        return o;
      });
  }

  private clearOverlay(msg?: string): void {
    this.overlays.forEach((p) => {
      p.then((o) => o.dismiss());
    });
    this.overlays = [];
    if (msg) this.toastService.presentToast(msg, 3000, 'middle').then();
  }

  async showAlert(header: string, message: string): Promise<void> {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: 'Okay',
          handler: () => { /* ignored */ },
        },
      ],
    });
    await alert.present();
  }
}

export interface TimeSheet {
  periodId?: number;
  closed: boolean;
  punches: PunchInterface[];
}
