import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { ReplaySubject } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { ConnectionStatus, NetworkService } from './network.service';

export interface AppDataInterface {
  version: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppUpdaterService {
  readonly updateAvailable = new ReplaySubject<string>(1);

  constructor(
    private readonly networkService: NetworkService,
    private readonly swUpdate: SwUpdate,
    private readonly loadingCtrl: LoadingController,
  ) {
    this.swUpdate.versionUpdates
      .pipe(
        tap((evt) => {
          console.log('pre-filter update event: ', evt);
        }),
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        tap((evt) => {
          console.log('post-filter update event: ', evt);
        }),
        map((evt) => (evt.latestVersion.appData as AppDataInterface).version),
      )
      .subscribe((version) => {
        this.updateAvailable.next(version);
      });

    // this.swUpdate.available
    //   .pipe(
    //     tap((v) => console.log('update: ', v)),
    //     filter((v) => v.type === 'UPDATE_AVAILABLE'),
    //     map((vObj) => (vObj.available.appData as AppDataInterface).version)
    //   )
    //   .subscribe((version) => {
    //     this.updateAvailable.next(version);
    //   });

    if (this.swUpdate.isEnabled) {
      this.networkService.onNetworkChange
        .pipe(
          filter((status) => status.status === ConnectionStatus.Online),
          first(),
          tap((v) => console.log('sw checkForUpdate; status: ', v)),
        )
        .subscribe(() => this.swUpdate.checkForUpdate().then());
    }
  }

  updateApp(): void {
    this.loadingCtrl
      .create({
        message: 'Updating, please wait...',
      })
      .then((overlay) => {
        overlay.present();
        this.swUpdate.activateUpdate().then(() => document.location.reload());
      });
  }
}
