import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Device } from '@capacitor/device';
import { AlertController } from '@ionic/angular';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { InactivityService } from './inactivity.service';
import { LoggingService } from './logging.service';
import { LocalStorageService } from './local-storage.service';
import { UbwUserInterface, UserPresetsInterface } from '../interfaces';
import { AppStorageService } from './app-storage.service';
import { ApiService } from './api.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class KioskService {
  constructor(
    private readonly auth: AuthService,
    private readonly inactivityService: InactivityService,
    private readonly logger: LoggingService,
    private readonly router: Router,
    private readonly storageService: AppStorageService,
    private readonly localStorage: LocalStorageService,
    private readonly apiService: ApiService,
    private readonly alertController: AlertController,
    private readonly toastService: ToastService,
  ) {
    this.storageService.setOnUsersUpdated(this.saveConfig);
    this.inactivityService.ended$.subscribe(() => this.reset());
  }

  startTimer(): void {
    this.inactivityService.startTimer();
  }

  async signOut(): Promise<void> {
    const { userPin } = await firstValueFrom(this.storageService.userPresets$);
    if (!!userPin) {
      this.inactivityService.stopTimer();
    } else {
      const subjectId = this.auth.subjectId;
      if (!!subjectId) {
        this.alertController
          .create({
            header: 'Sign Out?',
            message:
              'It appears you have not set your PIN yet. You must have a PIN in order to sign in on this device. If you continue without a PIN, this user will be removed from this device.',
            buttons: [
              {
                text: 'Cancel',
                handler: () => { },
              },
              {
                text: 'Remove',
                handler: () => {
                  this.storageService.removeUser(subjectId).then(() => this.inactivityService.stopTimer());
                },
              },
            ],
          })
          .then((alert) => alert.present());
      } else {
        this.inactivityService.stopTimer();
      }
    }
  }

  reset(): void {
    // this.logger.information('kioskReset', { loc: 'kiosk: 73' });
    this.auth.subjectId = '';
    this.router.navigate(['/switch-user'], { replaceUrl: true }).then(() => {
      this.storageService.setUbwUser({ ubwUser: null, getLocal: true });
      this.storageService.setUserPresets(null);
      this.storageService.setLastPunch({
        punchId: null,
        action: 'Clock Out',
        status: 'undefined',
        label: 'Clock In/Out',
        bunit: null,
        project: null,
        in: null,
        out: null,
        closed: false,
        approved: false,
      });
      // check for and remove profile
      this.auth.profile$
        .pipe(
          tap((profile) => console.log('profile: ', profile)),
          first(),
          filter((profile) => !!profile && this.storageService.isOnline),
        )
        .subscribe(() => {
          this.auth.signOutOfAuth().subscribe();
        });
    });
  }

  async confirmSaveConfig(): Promise<void> {
    if (this.storageService.isOnline) {
      let confirmed = false;
      const alert = await this.alertController.create({
        header: 'Save Config',
        message: `Are you sure you want to save this kiosk config?</br>
                      <b>NOTE: This will not affect users access.</b>`,
        backdropDismiss: false,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => { /* ignored */ },
          },
          {
            text: 'Save',
            role: 'ok',
            handler: () => {
              confirmed = true;
            },
          },
        ],
      });
      alert.present().then();
      await alert.onDidDismiss();
      if (confirmed) {
        const users = await this.localStorage.getUsers();
        this.saveConfig(users, true);
      }
    } else {
      await this.storageService.showAlert(
        'Load Configuration',
        'Oops, it appears you an not online. You must be online to load a configuration.',
      );
    }
  }

  async saveConfig(users: UbwUserInterface[], confirm: boolean = false): Promise<void> {
    interface DeviceInterface {
      operatingSystem: string;
      osVersion: string;
      model: string;
      manufacturer: string;
    }

    interface UserDataInterface {
      user: UbwUserInterface;
      presets: UserPresetsInterface;
      token: string;
    }

    console.log('save kiosk config; users: ', users);
    const tokens = this.auth.getRefreshTokens();
    const { operatingSystem, osVersion, model, manufacturer } = await Device.getInfo();
    const userData: UserDataInterface[] = [];
    for (const user of users) {
      const presets = await this.localStorage.getPresets(user.subjectId);
      userData.push({ user, presets, token: tokens[user.subjectId] });
    }
    try {
      await lastValueFrom(
        this.apiService.postData<
          {
            device: DeviceInterface;
            userData: UserDataInterface[];
          },
          void
        >('session/kiosk-config', {
          device: { operatingSystem, osVersion, model, manufacturer },
          userData,
        }, false),
      );
      if (confirm) {
        await this.storageService.showAlert(
          'Save Configuration',
          'Configuration saved successfully.',
        );
      }
    } catch (e) {
      await this.toastService.presentToast('Save kiosk config failed.Please contact support', 3000, 'middle');
    }
  }

  async confirmLoadConfig(): Promise<void> {
    if (this.storageService.isOnline) {
      let sessionId: string;
      const alert = await this.alertController.create({
        header: 'Load Configuration',
        message: 'Enter the session id of the configuration you would like to load.',
        inputs: [
          {
            name: 'sessionId',
            type: 'text',
            placeholder: 'Session ID',
          },
        ],
        backdropDismiss: false,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => { /* ignored */ },
          },
          {
            text: 'Load',
            handler: (data) => {
              sessionId = data.sessionId;
            },
          },
        ],
      });
      alert.present().then();
      await alert.onDidDismiss();
      if (sessionId) {
        await this.loadConfig(sessionId);
      }
    } else {
      await this.storageService.showAlert(
        'Load Configuration',
        'Oops, it appears you an not online. You must be online to load a configuration.',
      );
    }
  }

  async loadConfig(sessionId: string): Promise<void> {
    if (!!sessionId) {
      const overlay = this.storageService.showOverlay('Loading configuration... please wait.');
      try {
        const kioskData = await this.apiService.getKioskData(sessionId);
        const tokens: { [index: string]: string } = {};
        if (!!kioskData) {
          const users: UbwUserInterface[] = [];
          const userData = kioskData.userData;
          for (const item of userData) {
            users.push(item.user);
            tokens[item.user.subjectId] = item.token;
            await this.localStorage.setPresets(item.user.subjectId, item.presets);
          }
          this.auth.setRefreshTokens(tokens);
          await this.storageService.setUsers(users, false);
          await this.storageService.showAlert('Load Configuration', 'Configuration loaded successfully.');
        } else {
          await this.storageService.showAlert(
            'Load Configuration',
            'Load kiosk config failed. Please check your session id or contact support.',
          );
        }
      } catch (e) {
        await this.storageService.showAlert(
          'Load Configuration',
          'Oops, there was an error loading this configuration. Please contact support or try again. Error: ' +
          JSON.stringify(e),
        );
      } finally {
        overlay.then((o) => o.dismiss());
      }
    } else {
      await this.storageService.showAlert(
        'Load Configuration',
        'No session id supplied. Please enter the session id of the configuration you want to load.',
      );
    }
  }
}
