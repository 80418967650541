import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { LoggingService } from './logging.service';
import {
  NoticeInterface,
  Project,
  PunchInterface,
  TimesheetPeriodInterface,
  UbwUserInterface,
  UserPresetsInterface,
} from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private readonly storageKey: string = 'org.christianaidministries.timeclock';

  constructor(
    private readonly storage: Storage,
    private readonly logger: LoggingService,
  ) { }

  async getUsers(): Promise<UbwUserInterface[]> {
    return (await this.storage.get(`${this.storageKey}/users`)) as UbwUserInterface[];
  }

  async setUsers(users: UbwUserInterface[]): Promise<void> {
    await this.storage.set(`${this.storageKey}/users`, users);
  }

  async getPeriods(year: number): Promise<TimesheetPeriodInterface[]> {
    const years = [year - 1, year, year + 1];
    const periodsGroupedByYear = await Promise.all(
      years.map(
        (year) => this.storage.get(`${this.storageKey}/periods:${year}`) as Promise<TimesheetPeriodInterface[] | null>,
      ),
    );
    return periodsGroupedByYear.reduce((acc, current) => acc.concat(current ?? []), []);
  }

  async setPeriods(year: number, periods: TimesheetPeriodInterface[]): Promise<void> {
    await this.storage.set(`${this.storageKey}/periods:${year}`, periods);
  }

  async getProjects(): Promise<Project[]> {
    return (await this.storage.get(`${this.storageKey}/projects`)) as Project[];
  }

  async setProjects(projects: Project[]): Promise<void> {
    await this.storage.set(`${this.storageKey}/projects`, projects);
  }

  async getPresets(subjectId: string): Promise<UserPresetsInterface> {
    const presets = (await this.storage.get(`${this.storageKey}/presets/${subjectId}`)) as UserPresetsInterface;
    // remove subjectId if present, Feb 2024
    if (presets && presets['subjectId']) {
      this.logger.information('removing subjectId from local presets ', { loc: 'local-storage: 58', subjectId: presets['subjectId'] });
      delete presets['subjectId'];
    }
    return presets;
  }

  async setPresets(subjectId: string, presets: UserPresetsInterface): Promise<void> {
    await this.storage.set(`${this.storageKey}/presets/${subjectId}`, presets);
  }

  async getPunches(subjectId: string): Promise<{ periodId: number; closed: boolean; punches: PunchInterface[] }[]> {
    const data = await this.storage.get(`${this.storageKey}/punches/${subjectId}`);
    return (
      !!data && data.constructor !== Array
        ? [{ periodId: data.punches[0].periodId, closed: data.closed, punches: data.punches }]
        : data
    ) as { periodId: number; closed: boolean; punches: PunchInterface[] }[];
  }

  async setPunches(
    subjectId: string,
    pdata: { periodId: number; closed: boolean; punches: PunchInterface[] }[],
  ): Promise<void> {
    await this.storage.set(`${this.storageKey}/punches/${subjectId}`, pdata);
  }

  async getNotices(subjectId: string): Promise<NoticeInterface[]> {
    return (await this.storage.get(`${this.storageKey}/notices/${subjectId}`)) ?? ([] as NoticeInterface[]);
  }

  async setNotices(subjectId: string, notices: NoticeInterface[]): Promise<void> {
    await this.storage.set(`${this.storageKey}/notices/${subjectId}`, notices);
  }

  async getLastModified(idx: string): Promise<string> {
    const data = (await this.storage.get(`${this.storageKey}/lastModified`)) as { [index: string]: string };
    return data ? data[idx] : null;
  }

  async setLastModified(idx: string, dateModified: string): Promise<void> {
    const data = (await this.storage.get(`${this.storageKey}/lastModified`)) ?? {};
    // if (data.periods) delete data.periods; // clean up legacy data
    data[idx] = dateModified;
    await this.storage.set(`${this.storageKey}/lastModified`, data);
  }

  async removeNotices(subjectId: string): Promise<void> {
    await this.storage.remove(`${this.storageKey}/notices/${subjectId}`);
  }

  async removePresets(subjectId: string): Promise<void> {
    await this.storage.remove(`${this.storageKey}/presets/${subjectId}`);
  }

  async removePunches(subjectId: string): Promise<void> {
    await this.storage.remove(`${this.storageKey}/punches/${subjectId}`);
  }

  async removeUser(subjectId: string): Promise<void> {
    await this.removeNotices(subjectId);
    await this.removePresets(subjectId);
    await this.removePunches(subjectId);
  }
}
