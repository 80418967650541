<ion-app>
  <ion-menu side="start" menuId="first" contentId="main">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title color="light20">Menu</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-list ngClass="background-spacer">
        <ion-menu-toggle auto-hide="true">
          <ion-item [disabled]="!auth.subjectId" button [routerLink]="['/notifications']" [replaceUrl]="true" detail>
            <ion-icon name="megaphone" slot="start"></ion-icon>
            <ion-label>Notifications</ion-label>
          </ion-item>
          <ion-item [disabled]="!auth.subjectId" [routerLink]="['/punches']" [replaceUrl]="true" detail>
            <ion-icon name="hourglass" slot="start"></ion-icon>
            <ion-label>Punches</ion-label>
          </ion-item>
          <ion-item [disabled]="!auth.subjectId" [routerLink]="['/time-sheets']" [replaceUrl]="true" detail>
            <ion-icon name="timer" slot="start"></ion-icon>
            <ion-label>Timesheets</ion-label>
          </ion-item>
          <ion-item [routerLink]="['/user-config']" [replaceUrl]="true" detail>
            <ion-icon name="settings" slot="start"></ion-icon>
            <ion-label>Configuration</ion-label>
          </ion-item>
          <ion-item button (click)="onSignInOut()" detail>
            <ion-icon [name]="auth.subjectId ? 'log-out-sharp' : 'log-in-sharp'" slot="start"></ion-icon>
            <ion-label>{{ auth.subjectId ? 'Sign Out' : 'Sign In' }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main"></ion-router-outlet>
</ion-app>
