import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ConnectionStatus, NetworkService } from './network.service';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private entries: LogEntry[] = [];
  private isOnline: boolean;

  constructor(
    private readonly http: HttpClient,
    private readonly networkService: NetworkService,
  ) {
    interval(3000)
      .pipe(
        filter(() => this.isOnline && this.entries.length > 0),
        map(() => {
          const entries = this.entries;
          this.entries = [];
          return entries;
        }),
        switchMap((entries) =>
          this.http.post<void>('/api/logs', entries, {
            headers: {
              'Device-Time': new Date().toISOString(),
            },
          }),
        ),
      )
      .subscribe();

    this.networkService.onNetworkChange.subscribe(
      (status) => (this.isOnline = status.status === ConnectionStatus.Online),
    );
  }

  debug(template: string, properties: { [key: string]: any }): void {
    this.sendLog('debug', template, properties);
  }

  information(template: string, properties: { [key: string]: any }): void {
    this.sendLog('information', template, properties);
  }

  warning(template: string, properties: { [key: string]: any }): void {
    this.sendLog('warning', template, properties);
  }

  error(template: string, properties: { [key: string]: any }): void {
    this.sendLog('error', template, properties);
  }

  critical(template: string, properties: { [key: string]: any }): void {
    this.sendLog('critical', template, properties);
  }

  private sendLog(level: string, template: string, properties: { [key: string]: any }): void {
    const formatted = this.templateString(template, properties);
    console.log(`${level.toLocaleUpperCase()}: ${formatted}`, properties);
    this.entries.push({
      time: new Date().toISOString(),
      level,
      message: formatted,
      properties,
    });
  }

  private templateString(template: string, properties: { [key: string]: any }) {
    let output = template;
    Object.keys(properties).forEach((key) => {
      output = output.replace(new RegExp('\\$' + `{${key}}`, 'g'), properties[key]);
    });
    return output;
  }
}

interface LogEntry {
  time: string;
  level: string;
  message: string;
  properties: { [key: string]: any };
}
