import { Component, ViewChild } from '@angular/core';
import { Platform, IonRouterOutlet } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStorageService, AuthService, KioskService } from './services';
import { setOptions } from '@mobiscroll/angular';
import { filter, map, tap } from 'rxjs/operators';

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild(IonRouterOutlet) routerOutlet: IonRouterOutlet;

  constructor(
    readonly auth: AuthService,
    private readonly kioskService: KioskService,
    private readonly platform: Platform,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly storageService: AppStorageService,
  ) {
    this.initializeApp();
  }

  initializeApp(): void {
    console.log('initialize app');
    this.route.queryParamMap
      .pipe(
        map((param) => param.get('autoSignIn')),
        filter((v) => v === 'true'),
        tap(() => this.auth.clear()),
      )
      .subscribe(() => this.auth.signIn());
  }

  async onSignInOut(): Promise<void> {
    const isSignedIn = !!this.auth.subjectId;
    console.log('onSignInOut called, isSignedIn: ', isSignedIn);
    if (this.storageService.appMode === 'kiosk') {
      if (isSignedIn) {
        await this.kioskService.signOut();
      } else {
        await this.router.navigate(['/switch-user'], { replaceUrl: true });
      }
    } else {
      if (isSignedIn) {
        this.auth.signOut();
      } else {
        this.auth.signIn();
      }
    }
  }
}
