import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, EMPTY, from, Observable, of, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { firstValueFrom } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthStorage } from './auth-storage.service';
import { AuthUserInterface } from '../interfaces';
import { LoggingService } from './logging.service';

export const authConfig: AuthConfig = {
  issuer: environment.authority,
  redirectUri: window.location.origin,
  clientId: environment.clientId,
  responseType: 'code',
  scope: `openid profile offline_access resno IdentityServerApi ${environment.apiResource}`,
  showDebugInformation: true,
};

export interface AuthState {
  userData: AuthUserInterface;
}

export class UserRole {
  email: string;
  roleName: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isOnline: boolean; // gets set from app-storage.service onNetworkChange Observable
  readonly isAuthenticated$ = new BehaviorSubject(false);
  readonly profile$ = new ReplaySubject<AuthUserInterface | null>(1);
  private readonly refreshTokensLoaded$ = new BehaviorSubject(false);
  // readonly baseUrl: string;
  private readonly localStorage: Storage;

  constructor(
    private readonly alertController: AlertController,
    private readonly http: HttpClient,
    private readonly oauthService: OAuthService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly storage: AuthStorage,
    private readonly logger: LoggingService,
  ) {
    this.oauthService.configure(authConfig);
    // this.baseUrl = environment.authority;
    this.localStorage = window.localStorage;
    this.loadRefreshTokens();

    this.oauthService.events
      .pipe(
        // tap(v => console.log('oauthService event: ', v)),
        distinctUntilChanged(),
      )
      .subscribe((event) => {
        console.log('EVENT: ', event);
        if (event.type === 'token_received') {
          this.isAuthenticated$.next(true);
          if (!this.subjectId) {
            this.oauthService.loadUserProfile().then(
              (userInfo: {
                info: {
                  email: string;
                  family_name: string;
                  given_name: string;
                  picture: string;
                  resno: string;
                  sub: string;
                  username: string;
                  osub?: string;
                };
              }) => {
                const profile = userInfo.info;
                this.storage.activeSubjectId = profile.sub;
                console.log('auth profile: ', profile);
                this.profile$.next({
                  authResno: profile.resno,
                  familyName: profile.family_name,
                  givenName: profile.given_name,
                  isOriginal: !profile.osub || profile.sub === profile.osub,
                  username: profile.username,
                  subjectId: profile.sub,
                });
                this.storeRefreshTokens().subscribe();
              },
            );
          }
          // remove query params
          this.route.queryParamMap
            .pipe(
              map((q) => q.get('code')),
              filter((code) => !!code),
              switchMap((_) =>
                from(
                  this.router.navigate([], {
                    queryParams: { code: null, scope: null, state: null, session_state: null },
                    queryParamsHandling: 'merge',
                    replaceUrl: true,
                  }),
                ),
              ),
            )
            .subscribe();
        } else if (event.type === 'token_refresh_error') {
          this.signIn();
        } else if (event.type === 'code_error') {
          const error = event as OAuthErrorEvent;
          if ((error.params as any).error === 'access_denied') {
            this.alertController
              .create({
                header: 'Authentication',
                message: 'It appears you are not authorized yet. Click Sign In and enter your credentials to continue.',
                buttons: [
                  {
                    text: 'Okay',
                    handler: () => { },
                  },
                ],
              })
              .then((alert) => alert.present());
          }
        }
      });
  }

  get subjectId(): string {
    return this.storage.activeSubjectId;
  }

  set subjectId(value: string) {
    const subjectId = this.subjectId;
    console.log('auth set subjectId: ', 'new: ' + value, 'current: ' + subjectId);
    if (value === subjectId) {
      return;
    }

    this.storage.activeSubjectId = value;
    this.isAuthenticated$.next(false);

    if (!!value && this.isOnline) {
      this.refreshTokenIfNeeded().then();
    }
  }

  removeUser(subjectId: string): void {
    this.storage.removeUser(subjectId);
  }

  async checkDiscoveryDocument(): Promise<void> {
    this.logger.information('checkDiscoveryDocument called', { loc: 'auth: 157' });
    if (!this.oauthService.discoveryDocumentLoaded) {
      this.logger.information('no discovery document, calling loadDiscoveryDocument', { loc: 'auth: 159' });
      await this.oauthService.loadDiscoveryDocument();
      this.logger.information('loadDiscoveryDocument finished', { loc: 'auth: 161' });
    }
    await this.refreshTokenIfNeeded();
    this.logger.information('refreshTokenIfNeeded finished', { loc: 'auth: 164' });
  }

  async refreshTokenIfNeeded(): Promise<void> {
    this.logger.information('refreshTokenIfNeeded called', { loc: 'auth: 168', subjectId: this.subjectId });
    if (this.subjectId) {
      await firstValueFrom(
        this.refreshTokensLoaded$.pipe(
          tap((loaded) =>
            this.logger.information('refreshTokensLoaded observable, value: ' + loaded, {
              loc: 'auth: 145',
              loaded,
            }),
          ),
          filter((loaded) => loaded),
        ),
      );
      if (this.storage.getItem('refresh_token')) {
        this.logger.information('refreshing token', { loc: 'auth: 182' });
        await this.oauthService.refreshToken();
      } else {
        this.logger.information('calling SignIn', { loc: 'auth: 185' });
        this.signIn();
      }
    } else {
      this.logger.information('calling tryLogin', { loc: 'auth: 189' });
      await this.oauthService.tryLogin();
    }
  }

  clear(): void {
    this.storage.clear();
  }

  getRefreshTokens(): Record<string, string> {
    return this.storage.refreshTokens;
  }

  setRefreshTokens(refreshTokens: Record<string, string>): void {
    for (const subjectId in refreshTokens) {
      this.storage.refreshTokens[subjectId] = refreshTokens[subjectId];
    }
    this.storeRefreshTokens().subscribe();
  }

  loadRefreshTokens(): void {
    console.log('RT: about to load refreshTokens');
    this.http
      .get<{ [index: string]: string }>(`/api/session/refresh-tokens`)
      .pipe(
        // tap(tokens => console.log('RT: loaded tokens from server: ', tokens)),
        tap((_) => this.localStorage.removeItem('refreshTokens')),
        catchError((_) =>
          of<{ [index: string]: string }>(JSON.parse(this.localStorage.getItem('refreshTokens') ?? '{}')).pipe(
            switchMap((refreshTokens) =>
              Object.keys(refreshTokens).length > 0
                ? this.http.post('/api/session/refresh-tokens', refreshTokens).pipe(map((_) => refreshTokens))
                : of(refreshTokens),
            ),
          ),
        ),
        tap((refreshTokens) => {
          for (const subjectId in refreshTokens) {
            this.storage.refreshTokens[subjectId] = refreshTokens[subjectId];
          }
          this.refreshTokensLoaded$.next(true);
          console.log('RT: refresh tokens in memory', this.storage.refreshTokens);
        }),
      )
      .subscribe();
  }

  storeRefreshTokens(): Observable<void> {
    return this.http.post<void>('/api/session/refresh-tokens', this.storage.refreshTokens);
  }

  signIn(): void {
    if (this.isOnline) {
      this.subjectId = '';
      if (this.oauthService.discoveryDocumentLoaded) {
        this.oauthService.initLoginFlow();
      } else {
        this.oauthService.loadDiscoveryDocument().then((_) => this.oauthService.initLoginFlow());
      }
    } else {
      this.alertController
        .create({
          header: 'Sign In',
          message: 'You must be online to sign in. Please check your connection and try again.',
          buttons: [
            {
              text: 'Okay',
              handler: () => { },
            },
          ],
        })
        .then((alert) => alert.present());
    }
  }

  signOut(): void {
    console.log('signOut');
    if (this.isOnline) {
      this.http
        .get('https://auth.cam.ngo/api/authorization', {
          withCredentials: true,
        })
        .pipe(
          tap((_) => {
            console.log('calling logOut');
            this.oauthService.logOut();
            this.subjectId = '';
          }),
          catchError((_) => {
            console.log('calling setItem');
            this.storage.setItem('refresh_token', null);
            this.subjectId = '';
            this.profile$.next(null);
            return EMPTY;
          }),
        )
        .subscribe();
    } else {
      this.alertController
        .create({
          header: 'Sign In',
          message: 'You must be online to sign out. Please check your connection and try again.',
          buttons: [
            {
              text: 'Okay',
              handler: () => { },
            },
          ],
        })
        .then((alert) => alert.present());
    }
  }

  signOutOfAuth(): Observable<void> {
    console.log('signing out of auth');
    this.profile$.next(null);
    return this.http.post<void>(
      'https://auth.cam.ngo/api/sign-out',
      {},
      {
        withCredentials: true,
      },
    );
  }
}

export interface PersonalInfo {
  givenName: string;
  familyName: string;
  resno: string;
}

export interface AuthInterface {
  isAuthenticated$: Observable<boolean>;
  profile$: Observable<AuthUserInterface>;

  signIn(): Promise<void>;

  signOut(): Promise<void>;
}
