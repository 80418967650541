import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';

@Injectable()
export class AuthStorage implements OAuthStorage {
  readonly refreshTokens: { [index: string]: string } = {};
  private cache: { [index: string]: string } = {};
  private refreshToken = '';
  private subjectId = '';
  private readonly localStorage: Storage;

  constructor() {
    this.localStorage = window.localStorage;
    this.subjectId = this.localStorage.getItem('subjectId') ?? '';
  }

  get accessToken(): string {
    return this.getItem('access_token');
  }

  get activeSubjectId(): string {
    return this.subjectId;
  }

  set activeSubjectId(value: string) {
    // console.log('activeSubjectId: ', value);
    this.subjectId = value;
    this.localStorage.setItem('subjectId', value);

    if (value) {
      if (this.refreshToken) {
        this.refreshTokens[this.subjectId] = this.refreshToken;
        this.refreshToken = '';
      }

      const newCache: { [index: string]: string } = {};
      for (const key of Object.keys(this.cache)) {
        // console.log('STORING CACHED: ', key, this.cache[key]);
        if (key.startsWith(':')) {
          newCache[`${this.subjectId}${key}`] = this.cache[key];
        } else {
          newCache[key] = this.cache[key];
        }
      }

      this.cache = newCache;
    }
  }

  getItem(key: string): string {
    switch (key) {
      case 'refresh_token':
        const subjectId = this.subjectId;
        if (subjectId) {
          return this.refreshTokens[subjectId];
        } else {
          return this.refreshToken;
        }
      case 'nonce':
      case 'PKCE_verifier':
      case 'session_state':
        return this.localStorage.getItem(key);
      default:
        return this.cache[`${this.subjectId}:${key}`];
    }
  }

  getAccessToken(subjectId: string): string {
    return this.cache[`${subjectId}:access_token`];
  }

  setItem(key: string, data: string): void {
    switch (key) {
      case 'refresh_token':
        if (this.subjectId) {
          this.refreshTokens[this.subjectId] = data;
          // console.log('refreshTokens: ', this.refreshTokens);
        } else {
          this.refreshToken = data;
          console.log('refreshToken: ', this.refreshToken);
        }
        break;
      case 'nonce':
      case 'PKCE_verifier':
      case 'session_state':
        this.localStorage.setItem(key, data);
        break;
      default:
        this.cache[`${this.subjectId}:${key}`] = data;
      // console.log('auth cache; sub: ' + this.subjectId, JSON.stringify(this.cache));
    }
  }

  removeItem(key: string): void {
    switch (key) {
      case 'nonce':
      case 'PKCE_verifier':
      case 'session_state':
        this.localStorage.removeItem(key);
        break;
      default:
    }
  }

  removeUser(subjectId: string): void {
    this.refreshTokens[subjectId] = null;
    // this.localStorage.setItem('refreshTokens', JSON.stringify(this.refreshTokens));
  }

  clear(): void { }
}
