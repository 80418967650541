function count<T>(obj: T): number {
  let countOfProperties = 0;
  for (const k in obj) {
    if (obj.hasOwnProperty(k)) {
      countOfProperties++;
    }
  }
  return countOfProperties;
}

export function checkObjectEquals(valueA: any, valueB: any): boolean {
  if (typeof valueA !== typeof valueB) {
    console.log('checkObject type mismatch', valueA, valueB);
    return false;
  }

  if (typeof valueA === 'function') {
    const r = valueA.toString() === valueB.toString();
    if (!r) console.log('checkObject value mismatch: ', valueA, valueB);
    return r;
  }

  if (valueA instanceof Object && valueB instanceof Object) {
    if (count(valueA) !== count(valueB)) {
      console.log('checkObject member count mismatch.');
      return false;
    }
    let areEqual = true;
    for (const k in valueA) {
      areEqual = checkObjectEquals(valueA[k], valueB[k]);
      if (!areEqual) {
        console.log('checkObject member mismatch: ', k);
        return false;
      }
    }
    return true;
  } else {
    const areEqual = valueA === valueB;
    if (!areEqual) {
      console.log('checkObject value mismatch: ', valueA, valueB);
    }
    return areEqual;
  }
}
