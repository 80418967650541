import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, Subject, firstValueFrom } from 'rxjs';
import { tap, catchError, filter, first, switchMap, distinctUntilChanged, throttleTime, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LoggingService } from './logging.service';
import packageJson from '../../../package.json';
import { KioskDataInterface } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly called$ = new Subject<void>();

  constructor(
    private readonly logger: LoggingService,
    private readonly http: HttpClient,
    private readonly auth: AuthService,
  ) {
    // record version info from client
    merge(
      this.called$.pipe(),
      this.auth.isAuthenticated$.pipe(
        distinctUntilChanged(),
        filter((auth) => auth && this.auth.isOnline),
      ),
    )
      .pipe(throttleTime(60000))
      .subscribe((_) => {
        try {
          const subjectId = this.auth.subjectId;
          // const { Device } = Plugins;
          // const deviceInfo = await Device.getInfo();
          // console.log('info: ', deviceInfo);
          this.postData<{ subjectId: string; version: string }, void>('client-info', {
            subjectId,
            version: packageJson.version,
          }, false).subscribe();
        } catch (e) {
          this.logger.error('record version error: ', e);
        }
      });
  }

  getData<T>(
    model: string,
    options?: {
      includeLastModified?: boolean;
      ifModifiedSince?: string;
    },
  ): Observable<{ data: T; lastModified: string } | T> {
    const inst = Math.round(Math.random() * 1000);
    this.logger.information(`getData called`, { loc: 'api: 54', inst, model });
    const headers = options?.ifModifiedSince ? { 'If-Modified-Since': options.ifModifiedSince } : {};
    return this.auth.isAuthenticated$.pipe(
      filter((auth) => auth),
      first(),
      switchMap((_) => this.http.get<T>(`api/${model}`, { headers, observe: 'response' })),
      catchError((err) => {
        this.logger.error(`getData error`, { loc: 'api: 61', inst, err });
        throw err;
      }),
      map((response) =>
        options?.includeLastModified
          ? {
            data: response.body,
            lastModified: response.headers.get('Last-Modified'),
          }
          : response.body,
      ),
      tap((res) => {
        this.logger.information(`getData info`, {
          loc: 'api: 74',
          inst,
          model,
          result: model === 'projects' ? {} : res,
        });
        // console.log('get: ' + model, res);
      }),
      tap((_) => this.called$.next()),
    );
  }

  putData<TBody, TResult>(url: string, model: string, data: TBody): Observable<TResult> {
    return this.auth.isAuthenticated$.pipe(
      filter((auth) => auth),
      first(),
      switchMap((_) => this.http.put<TResult>(`api/${url}${model}`, data)),
      catchError((err) => {
        throw err; // new Error(err);
      }),
      tap((_) => this.called$.next()),
    );
  }

  postData<TBody, TResult>(url: string, body: TBody, authRequired: boolean = true): Observable<TResult> {
    const inst = Math.round(Math.random() * 1000);
    this.logger.information(`postData called`, { loc: 'api: 99', inst, url, body });
    return this.auth.isAuthenticated$.pipe(
      // tap(_ => this.logger.information(`postData inside pipe`, {loc: 'api: 76', inst, body})),
      filter((auth) => auth || !authRequired),
      // tap(_ => this.logger.information(`postData after filter`, {loc: 'api: 77', url, body})),
      first(),
      // tap(_ => this.logger.information(`postData after filter() and first()`, {loc: 'api: 80', inst, body})),
      switchMap((auth) => this.http.post<TResult>(`api/${url}`, body)),
      catchError((err) => {
        this.logger.error(`postData error`, { loc: 'api: 108', inst, err });
        throw err; // new Error(err);
      }),
      tap(() => this.logger.information(`postData finished`, { loc: 'api: 111', inst, body })),
      tap(() => this.called$.next()),
    );
  }

  patchData<TBody, TResult>(url: string, body: TBody): Observable<TResult> {
    return this.auth.isAuthenticated$.pipe(
      filter((auth) => auth),
      first(),
      switchMap((_) => this.http.patch<TResult>(`api/${url}`, body)),
      catchError((err) => {
        throw err; // new Error(err);
      }),
      tap((_) => this.called$.next()),
    );
  }

  getKioskData(sessionId: string): Promise<KioskDataInterface> {
    return firstValueFrom(this.http.get<KioskDataInterface>(`api/session/${sessionId}/kiosk-config`)); // .toPromise();
  }
}
