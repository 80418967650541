import {Injectable} from '@angular/core';
import {formatDate} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {ToastController} from '@ionic/angular';
import {EMPTY, interval, merge, Observable, of, Subject} from 'rxjs';
import {catchError, distinctUntilChanged, filter, first, map, pairwise, switchMap, tap} from 'rxjs/operators';
import {ConnectionStatus, NetworkService} from './network.service';
import {LoggingService} from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class AppTimerService {
  public systemTimer: { value: number; source: string } = {value: null, source: null};
  private isOnline: boolean;
  private readonly restart$ = new Subject<void>();

  constructor(
    private readonly http: HttpClient,
    private readonly networkService: NetworkService,
    private readonly toastController: ToastController,
    private readonly logger: LoggingService,
  ) {
    merge(
      this.restart$.pipe(
        tap(() =>
          this.logger.information(
            `Clock restart called at device time: ${formatDate(Date.now(), 'mediumTime', 'en-us')}`,
            {
              loc: 'timer: 33',
              deviceTime: Date.now(),
            },
          ),
        ),
        switchMap(() =>
          this.isOnline
            ? this.getServerTime().pipe(map((time) => ({startTime: time, restarted: true})))
            : of({startTime: undefined, restarted: true}),
        ),
      ),
      this.networkService.onNetworkChange.pipe(
        tap((status) =>
          this.logger.information(
            `Clock network change detected; online: ${status.status === ConnectionStatus.Online}`,
            {
              loc: 'timer: 46',
              status,
            },
          ),
        ),
        tap((status) => (this.isOnline = status.status === ConnectionStatus.Online)),
        pairwise(),
        filter(([, curr]) => curr.status === ConnectionStatus.Online),
        switchMap(([prev]) =>
          this.getServerTime().pipe(
            tap((time) =>
              this.logger.information(`Server time received: ${formatDate(time, 'mediumTime', 'en-us')}`, {
                loc: 'timer: 58',
                time,
              }),
            ),
            map((time) => ({startTime: time, restarted: prev.status === ConnectionStatus.Offline})),
          ),
        ),
      ),
      this.networkService.onNetworkChange.pipe(
        filter((status) => status.status !== undefined),
        first(),
        filter((status) => status.status === ConnectionStatus.Offline),
        tap((status) => this.logger.information('Clock started offline', {loc: 'timer: 70', status})),
        map(() => ({startTime: undefined, restarted: false})),
      ),
    )
      .pipe(
        tap(({startTime, restarted}) =>
          this.logger.information(
            `Clock ${restarted ? 'restarted' : 'started'} at: ${formatDate(
              startTime ?? Date.now(),
              'mediumTime',
              'en-us',
            )}`,
            {
              loc: 'timer: 83',
              startTime,
              restarted,
            },
          ),
        ),
        switchMap(({startTime, restarted}) => {
          const before = performance.now();
          const dvcStart = Date.now();

          this.systemTimer.source = startTime ? 'server' : 'device';
          startTime = startTime ?? dvcStart;
          this.systemTimer.value = startTime;
          this.logger.information(
            `Clock init value: [${formatDate(this.systemTimer.value, 'mediumTime', 'en-us')}] and source: [${
              this.systemTimer.source
            }]`,
            {
              loc: 'timer: 99',
              startTime,
              before,
              dvcStart,
              restarted,
            },
          );
          if (restarted) {
            this.toastController
              .create({
                message: `Clock restarted at: ${formatDate(startTime, 'mediumTime', 'en-us')}`,
                duration: 5000,
                position: 'top',
              })
              .then((toast) => toast.present());
          }

          let prevDeviceNow = dvcStart;
          let prevPerformance = before;
          let prevTime = startTime;
          return interval(1000).pipe(
            map(() => {
              const deviceNow = Date.now();
              const currPerformance = performance.now();
              const elapsed = currPerformance - before;
              const restart = Math.abs(deviceNow - dvcStart - elapsed) > 60000 || currPerformance < prevPerformance;
              const time = restart ? deviceNow : startTime + elapsed;
              if (time < prevTime - 60000) {
                this.logger.warning(`Backwards clock movement detected.`, {
                  loc: 'timer: 128',
                  time,
                  restart,
                  elapsed,
                  startTime,
                  before,
                  dvcStart,
                  prev: {
                    prevDeviceNow,
                    prevPerformance,
                    prevTime,
                  },
                  curr: {
                    dvcNow: deviceNow,
                    currPerformance,
                  },
                });
              }
              prevDeviceNow = deviceNow;
              prevPerformance = currPerformance;
              prevTime = time;
              return {time, restart, elapsed};
            }),
          );
        }),
        tap((object) => {
          this.systemTimer.value = object.time;
          // restart timer every 24 hours, if online
          if (object.elapsed > (24 * 60 * 60 * 1000) && this.isOnline) {
            this.logger.information(`Restart timer called; over 24 hrs`, {loc: 'timer: 157', object});
            this.restart$.next();
          }
        }),
        distinctUntilChanged(undefined, (object) => object.restart),
        filter((object) => object.restart),
        tap((object) => this.logger.information('Clock restart detected.', {loc: 'timer: 163', object})),
      )
      .subscribe(() => this.restart$.next());
  }

  private getServerTime(): Observable<number> {
    return this.http.get<number>(`api/system-time`).pipe(
      tap((serverTime) => {
        const deviceTime = new Date().getTime();
        const difference = Math.abs(serverTime - deviceTime);

        if (difference > 600000) {
          this.logger.warning(
            `large time difference between server and device; difference: ${difference}, serverTime: ${serverTime}; deviceTime: ${deviceTime}`,
            {
              difference,
              serverTime,
              deviceTime,
            },
          );
        }
      }),
      catchError(() => EMPTY),
    );
  }
}
