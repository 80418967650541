import { Injectable, NgZone } from '@angular/core';
import { fromEvent, interval, merge, Observable, of, Subject } from 'rxjs';
import { first, map, skipWhile, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class InactivityService {
  private readonly inactivityTime: number = 20;
  private readonly stop$ = new Subject<void>();
  private readonly inactivityTimerEvents: Array<any>[] = [
    [document, 'click'],
    [document, 'wheel'],
    [document, 'scroll'],
    [document, 'mousemove'],
    [document, 'keyup'],
    [window, 'resize'],
    [window, 'scroll'],
    [window, 'mousemove'],
  ];
  private running = false;
  readonly ended$ = new Subject<void>();

  constructor(private readonly ngZone: NgZone) {}

  startTimer(): void {
    if (!this.running) {
      const eventsArray$: Observable<any>[] = this.inactivityTimerEvents.map((event) => fromEvent(event[0], event[1]));

      const events$ = merge(...eventsArray$, of(true));
      this.ngZone.runOutsideAngular(() => {
        events$
          .pipe(
            tap(() => console.log('timer starting')),
            tap(() => (this.running = true)),
            switchMap(() =>
              merge(
                interval(1000).pipe(
                  tap((i) => console.log('interval emitted')),
                  take(this.inactivityTime),
                ),
                this.stop$.pipe(
                  first(),
                  map(() => this.inactivityTime - 1),
                ),
              ),
            ),
            skipWhile((elap) => {
              return elap < this.inactivityTime - 1;
            }),
            tap(() => (this.running = false)),
            tap(() => console.log('timer ending')),
            first(),
          )
          .subscribe(() =>
            this.ngZone.run(() => {
              this.ended$.next();
            }),
          );
      });
    }
  }

  stopTimer(): void {
    if (this.running) {
      this.stop$.next();
    } else {
      this.ended$.next();
    }
  }
}
