import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { Platform, ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, skip } from 'rxjs/operators';

export enum ConnectionStatus {
  Online,
  Offline,
}

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private readonly status = new BehaviorSubject<{ status: ConnectionStatus | undefined; stamp: number | undefined }>({
    status: undefined,
    stamp: undefined,
  });
  private mode: ConnectionStatus = ConnectionStatus.Online;

  constructor(
    private readonly toastController: ToastController,
    private readonly plt: Platform,
  ) {
    this.plt.ready().then(async () => {
      this.initStatus().then();

      Network.addListener('networkStatusChange', (status) => {
        this.status.next({
          status:
            status.connected && this.mode === ConnectionStatus.Online
              ? ConnectionStatus.Online
              : ConnectionStatus.Offline,
          stamp: Date.now(),
        });
      });

      this.status
        .pipe(
          filter((status) => status.status !== undefined),
          skip(1),
        )
        .subscribe((status) => {
          const msg =
            this.mode === ConnectionStatus.Offline
              ? 'working Offline'
              : 'now' + (status.status === ConnectionStatus.Offline ? ' Offline' : ' Online');
          this.toastController
            .create({
              message: `You are ${msg}`,
              duration: 3000,
              position: 'bottom',
            })
            .then((toast) => toast.present());
        });
    });
  }

  get onNetworkChange(): Observable<{ status: ConnectionStatus; stamp: number }> {
    return this.status.asObservable();
  }

  get manualMode(): ConnectionStatus {
    return this.mode;
  }

  set manualMode(mode: ConnectionStatus) {
    this.mode = mode;
    this.initStatus().then();
  }

  getCurrentNetworkStatus(): { status: ConnectionStatus; stamp: number } {
    return this.status.getValue();
  }

  private async initStatus(): Promise<void> {
    const status = await Network.getStatus();
    this.status.next({
      status:
        status.connected && this.mode === ConnectionStatus.Online ? ConnectionStatus.Online : ConnectionStatus.Offline,
      stamp: Date.now(),
    });
  }
}
