import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  constructor(
    private loadingController: LoadingController,
    private toastController: ToastController,
  ) {}

  async getLocation(): Promise<string> {
    return 'disabled';
  }

  async showLoading(msg: string): Promise<any> {
    const overlay = await this.loadingController.create({
      message: msg,
    });
    overlay.present();
    return overlay;
  }

  async presentToast(msg: string): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration: 5000,
    });
    toast.present();
  }
}
