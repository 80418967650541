import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toast: HTMLIonToastElement;

  constructor(private readonly toastController: ToastController) {}

  async presentToast(msg: string, duration?: number, position?: 'top' | 'bottom' | 'middle'): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration: duration ?? 5000,
      position: position ?? 'bottom',
    });
    toast.present().then(() => {
      // ignored
    });
    this.toast = toast;
  }

  hideToast(): void {
    if (!!this.toast) {
      this.toast.dismiss().then(() => {
        // ignored
      });
    }
  }
}
